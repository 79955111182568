import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Grid, Paper } from "@material-ui/core"
import Layout from "../components/layout"
import SEO from "../components/seo"
import theme from "../theme"

const welcomeImgProps = {
  maxWidth: "350px",
  minHeight: "100px",
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
}

const titleProps = {
  letterSpacing: ".3rem",
  fontSize: "1.5rem",
  color: theme.palette.secondary.main,
}

const paperProps = {
  padding: theme.spacing(1),
  textAlign: "center",
}

const linkedInUrl = "https://www.linkedin.com/in/tnoor/"

class LinkedInRedirect extends React.Component {
  componentDidMount() {
    window.setTimeout(function () {
      window.location.href = linkedInUrl
    }, 3000)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            welcome: file(relativePath: { eq: "bitmoji/laptop_hi.png" }) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 375) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={(data) => {
          const welcomeImg = data.welcome.childImageSharp.fluid
          return (
            <Layout>
              <SEO title="TJ Noor - LinkedIn" />
              <section id="404" style={{ minHeight: "90vh" }}>
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  justify="center"
                  style={{ minHeight: "80vh" }}
                >
                  <Paper style={paperProps} className="p-5" elevation={0}>
                    <h1
                      className="p-3 text-uppercase bold90Spaced"
                      style={titleProps}
                    >
                      LinkedIn
                    </h1>
                    <Img
                      fluid={welcomeImg}
                      fadeIn={true}
                      alt="tj says hi from inside of a laptop"
                      style={welcomeImgProps}
                    />
                    <h3 className="p-3 text-uppercase boldSpacedPadded">
                      Redirecting you to <a href={linkedInUrl}>LinkedIn</a> in
                      just a few seconds ...
                    </h3>
                  </Paper>
                </Grid>
              </section>
            </Layout>
          )
        }}
      />
    )
  }
}

export default LinkedInRedirect
